<template>
  <div>
    <surveys-table surveyName="CAT" surveyCode="CAT"></surveys-table>
  </div>
</template>

<script>
import SurveysTable from "../../../components/Forms/SurveysTable.vue";
export default {
  components: {
    SurveysTable,
  },
};
</script>

<style scoped></style>
